.xmlDebugDiv {
    padding-left: 20px;
}
  
.xmlDebugElement {
    color: darkblue;
}

.xmlDebugAttKey {
    color: darkgreen;
}

.xmlDebugContainer {
    border: 1px solid #ccc;
    padding: 10px;
}
