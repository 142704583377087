#debug {
    position: fixed;
    z-index: 99;
    overflow: auto;
    width: 40%;
    height: 100%;
    background-color: white;
    right:0;
}

.xpathElementDetails {
    margin-top: 20px;
    padding-top: 20px;
    cursor: pointer;
    border-top: 1px solid #ccc;
}

.xpathToolsExpand {
    cursor: pointer;
}
